<template>
    <div id="app">
       <div class="head">
           <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/room/contactform/log.png" style="width: 20px;height: 20px;margin: 26px 10px 0 30px;float: left">
           <div style="font-size: 18px;color: #163091;font-weight: bold;float: left;margin-top: 25px;cursor: pointer">{{content.fd_1677731812206}}</div>
         <div class="conference">
            <div class="title">
                <div style="font-size: 60px;font-family: 'SimHei';">会议纪要</div>
            </div>

            <div style="margin-bottom: 50px">
                <div class="font-hei" style="display: flex; justify-content: space-between">
                    <div>{{content.fd_1678785633590}}</div>
                    <div>{{content.fd_1677729893953}}</div>
                </div>
                <div style="height: 3px; background-color: #FF0000; margin: 16px 0"></div>
            </div>

            <div class="content">
                <div class="font-hei" style="text-align: center; font-size: 30px; margin-bottom: 40px;color: #444444;font-family: 'SimHei';">{{content.fd_1677729356192}}</div>
                <div>
                    <div class="group" v-if="content.fd_1677729676876">
                        <span class="font-hei">会议主持：</span><span class="font-song">{{content.fd_1677729676876}}</span>
                    </div>
                    <div class="group" v-if="content.fd_1678790860819">
                        <span class="font-hei">会议地点：</span><span class="font-song">{{content.fd_1678790860819}}</span>
                    </div>
                    <div class="group" v-if="content.fd_1678785634478">
                        <span class="font-hei">参会部门：</span><span class="font-song">{{content.fd_1678785634478}}</span>
                    </div>
                    <div class="group" v-if="content.fd_1677732121952">
                        <span class="font-hei">参与人员：</span><span class="font-song">{{content.fd_1677732121952}}</span>
                    </div>
                    <div class="group" v-if="content.fd_1678785636174">
                        <span class="font-hei">记录人员：</span><span class="font-song">{{content.fd_1678785636174}}</span>
                    </div>
                    <div class="group" v-if="content.fd_1677730269799">
                        <div class="font-hei" style="margin-bottom: 8px">会议内容：</div>
                        <div v-html="content.fd_1677730269799" class="font-song" style="white-space: pre-wrap;"></div>
                    </div>

                    <div class="group" v-if="content.fd_1677732494851">
                        <div class="font-hei" style="margin-bottom: 8px">会议现场：</div>
                        <img :src="item.url" v-for="(item, index) in JSON.parse(content.fd_1677732494851)" :key="index" style="width: 100%">
                    </div>
                </div>
            </div>
        </div>
       </div>
    </div>

</template>

<script>
    export default {
        name: "conference",
        data() {
            return {
                content: {}
            }
        },

        mounted() {
            document.documentElement.scrollTop = document.body.scrollTop = 0;
            this.getRoomContent()
        },

        methods: {
            // 获取内容
            getRoomContent() {
                this.newApi.getRoomContentInfo({id: this.$route.params.id}).then(res =>{
                    this.content = res.data.content;
                })
            },
        }
    }
</script>
<style>
    .conference .font-song p img {
        width: 100%;
    }
</style>

<style scoped>
    .head {
      width: 1000px;
      margin: 0 auto;
      padding-top: 120px;
    }
    .conference {
        width: 800px;
        padding: 130px 100px;
        margin: 10px auto;
        min-height: 800px;
        background-color: #FFFFFF;
    }

    .conference .title {
        text-align: center;
        margin-bottom: 40px;
        font-size: 40px;
        color: #FF0000;
        clear: both;
    }

    .conference .group {
        margin-bottom: 16px;
    }

    .conference .font-hei {
        font-family: "SimHei";
        font-size: 22px;
        line-height: 30px;
    }

    .conference .font-song {
        font-family: "FangSong";
        font-size: 22px;
        line-height: 30px;
    }

    .conference .time {

    }
</style>